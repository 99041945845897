.loading-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(5px);
    animation: fadeIn 0.3s ease;
}

.loading-popup {
    background: white;
    padding: 30px 40px;
    border-radius: 15px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 90%;
    width: 400px;
    animation: slideIn 0.3s ease;

    &.error {
        border-left: 5px solid #e74c3c;
        .loading-spinner {
            border-top-color: #e74c3c;
        }
    }

    &.success {
        border-left: 5px solid #2ecc71;
        .loading-spinner {
            border-top-color: #2ecc71;
        }
    }

    &.loading {
        border-left: 5px solid #3498db;
        .loading-spinner {
            border-top-color: #3498db;
        }
    }

    p {
        margin: 15px 0 0;
        color: #2c3e50;
        font-size: 1.1rem;
        font-weight: 500;
    }
}

.loading-spinner {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
