.internship-section {
    padding: 80px 0;
    background-color: #f8f9fa;

    .section_heading {
        .heading_text {
            font-size: 36px;
            font-weight: 700;
            color: #1a1a1a;
            margin-bottom: 15px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
                width: 50px;
                height: 3px;
                background: linear-gradient(45deg, #007bff, #00bcd4);
            }
        }

        .heading_description {
            font-size: 18px;
            color: #666;
            max-width: 600px;
            margin: 20px auto 0;
        }
    }

    // Internship Cards
    .internship-card {
        height: 100%;
        background: #fff;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        overflow: hidden;
        border: none;

        &:hover {
            transform: translateY(-5px);
        }

        .card-body {
            padding: 25px;
        }

        .program-title {
            font-size: 20px;
            font-weight: 600;
            color: #1a1a1a;
            margin-bottom: 15px;
            position: relative;
            padding-bottom: 15px;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40px;
                height: 2px;
                background: linear-gradient(45deg, #007bff, #00bcd4);
            }
        }

        .program-details {
            margin-bottom: 20px;

            p {
                margin-bottom: 8px;
                color: #666;
                font-size: 14px;

                i {
                    margin-right: 8px;
                    color: #007bff;
                }
            }
        }

        .skills-section {
            h4 {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10px;
                color: #1a1a1a;
            }

            .skills-list {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                .skill-tag {
                    background: rgba(0, 123, 255, 0.1);
                    color: #007bff;
                    padding: 4px 12px;
                    border-radius: 20px;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    }

    // Benefits Section
    .benefits-section {
        background: #fff;
        padding: 50px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        margin: 50px 0;

        h2 {
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 40px;
            color: #1a1a1a;
        }

        .benefit-item {
            text-align: center;
            padding: 20px;
            transition: transform 0.3s ease;

            &:hover {
                transform: translateY(-5px);
            }

            i {
                font-size: 40px;
                color: #007bff;
                margin-bottom: 20px;
                background: linear-gradient(45deg, #007bff, #00bcd4);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            h3 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 15px;
                color: #1a1a1a;
            }

            p {
                color: #666;
                font-size: 15px;
                line-height: 1.6;
            }
        }
    }

    // Application Form
    .application-section {
        background: #fff;
        padding: 50px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

        h2 {
            font-size: 32px;
            font-weight: 700;
            color: #1a1a1a;
            margin-bottom: 30px;
        }

        .form-group {
            margin-bottom: 25px;

            .form-control {
                height: 50px;
                border: 2px solid #e1e1e1;
                border-radius: 8px;
                padding: 10px 15px;
                font-size: 15px;
                transition: all 0.3s ease;

                &:focus {
                    border-color: #007bff;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.1);
                }

                &.is-invalid {
                    border-color: #dc3545;
                }

                &::placeholder {
                    color: #999;
                    font-size: 14px;
                }
            }

            textarea.form-control {
                height: auto;
                resize: vertical;
            }
        }

        .invalid-feedback {
            font-size: 13px;
            color: #dc3545;
            margin-top: 5px;
        }

        .submit-btn {
            padding: 12px 40px;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 30px;
            background: linear-gradient(45deg, #007bff, #00bcd4);
            border: none;
            color: #fff;
            transition: all 0.3s ease;
            box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);

            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }

            &:hover:not(:disabled) {
                transform: translateY(-2px);
                box-shadow: 0 8px 20px rgba(0, 123, 255, 0.4);
            }

            &:active:not(:disabled) {
                transform: translateY(1px);
            }
        }

        .alert {
            margin-top: 25px;
            border-radius: 8px;
            font-size: 15px;
            font-weight: 500;
            
            &.alert-success {
                background-color: #d4edda;
                border-color: #c3e6cb;
                color: #155724;
            }
            
            &.alert-danger {
                background-color: #f8d7da;
                border-color: #f5c6cb;
                color: #721c24;
            }
        }
    }
}

// Responsive Styles
@media (max-width: 991px) {
    .internship-section {
        padding: 60px 0;

        .section_heading {
            .heading_text {
                font-size: 30px;
            }
        }

        .benefits-section {
            padding: 30px;
            
            .benefit-item {
                margin-bottom: 30px;
            }
        }

        .application-section {
            padding: 30px;
        }
    }
}

@media (max-width: 767px) {
    .internship-section {
        .section_heading {
            .heading_text {
                font-size: 26px;
            }

            .heading_description {
                font-size: 16px;
            }
        }

        .internship-card {
            margin-bottom: 20px;
        }

        .benefits-section {
            h2 {
                font-size: 26px;
            }
        }

        .application-section {
            h2 {
                font-size: 26px;
            }

            .submit-btn {
                padding: 10px 30px;
                font-size: 14px;
            }
        }
    }
}
