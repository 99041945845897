.it-form-section {
    padding: 80px 0;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);

    .section_heading {
        margin-bottom: 50px;

        .heading_text {
            font-size: 2.5rem;
            font-weight: 700;
            color: #2c3e50;
            margin-bottom: 1rem;
        }

        .heading_description {
            font-size: 1.1rem;
            color: #6c757d;
        }
    }

    .position-card {
        background: #ffffff;
        border-radius: 15px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
        transition: transform 0.3s ease;
        height: 100%;
        
        &:hover {
            transform: translateY(-5px);
        }

        .card-body {
            padding: 25px;
        }

        .position-title {
            font-size: 1.25rem;
            font-weight: 600;
            color: #2c3e50;
            margin-bottom: 1rem;
        }

        .position-details {
            margin-bottom: 1rem;

            p {
                margin-bottom: 0.5rem;
                color: #6c757d;
                font-size: 0.9rem;

                i {
                    margin-right: 8px;
                    color: #3498db;
                }
            }
        }

        .skills-section {
            h4 {
                font-size: 1rem;
                color: #2c3e50;
                margin-bottom: 0.5rem;
            }

            .skills-list {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                .skill-tag {
                    background: #e1f0ff;
                    color: #3498db;
                    padding: 4px 12px;
                    border-radius: 20px;
                    font-size: 0.8rem;
                }
            }
        }
    }

    .it-form-wrapper {
        background: #ffffff;
        padding: 50px;
        border-radius: 20px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);

        .form-group {
            margin-bottom: 1.5rem;

            .form-control {
                padding: 12px 15px;
                border: 1px solid #e1e8ef;
                border-radius: 10px;
                font-size: 0.95rem;
                transition: border-color 0.3s ease;

                &:focus {
                    border-color: #3498db;
                    box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.25);
                }

                &::placeholder {
                    color: #95a5a6;
                }

                &.is-invalid {
                    border-color: #e74c3c;
                }
            }

            textarea.form-control {
                resize: vertical;
            }

            .invalid-feedback {
                font-size: 0.85rem;
                color: #e74c3c;
            }
        }

        .submit-btn {
            background: linear-gradient(135deg, #3498db 0%, #2980b9 100%);
            border: none;
            padding: 12px 30px;
            font-size: 1rem;
            font-weight: 600;
            border-radius: 25px;
            transition: transform 0.3s ease;

            &:hover {
                transform: translateY(-2px);
            }

            &:disabled {
                background: #95a5a6;
                transform: none;
            }
        }

        .alert {
            border-radius: 10px;
            padding: 15px 20px;
            margin-top: 2rem;

            &.alert-success {
                background-color: #d4edda;
                border-color: #c3e6cb;
                color: #155724;
            }

            &.alert-danger {
                background-color: #f8d7da;
                border-color: #f5c6cb;
                color: #721c24;
            }
        }
    }

    .process-steps {
        .process-step {
            background: rgba(255, 255, 255, 0.05);
            border-radius: 10px;
            padding: 20px;
            transition: all 0.3s ease;
            border-left: 4px solid var(--theme-color);

            &:hover {
                transform: translateX(10px);
                background: rgba(255, 255, 255, 0.1);
            }

            .step-title {
                color: var(--theme-color);
                font-size: 1.2rem;
                margin-bottom: 10px;
                font-weight: 600;
            }

            p {
                margin-bottom: 0;
                line-height: 1.6;
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }

    .img-fluid.rounded.shadow-lg {
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.02);
        }
    }
}

.it_staffing_section {
    .image-block {
        position: relative;
        
        img.img-fluid {
            border-radius: 20px;
            box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease;
            
            &:hover {
                transform: translateY(-10px);
            }
        }
        
        .shape-decorator-1 {
            top: -30px;
            right: -20px;
            z-index: -1;
        }
        
        .shape-decorator-2 {
            bottom: -40px;
            left: -30px;
            z-index: -1;
        }
        
        .shape-decorator-3 {
            top: 50%;
            right: -40px;
            transform: translateY(-50%);
            z-index: -1;
        }
    }

    .floating-animation {
        animation: float 6s ease-in-out infinite;
    }

    .floating-animation-reverse {
        animation: float 6s ease-in-out infinite reverse;
    }

    .rotating-animation {
        animation: rotate 20s linear infinite;
    }

    @keyframes float {
        0%, 100% { transform: translateY(0); }
        50% { transform: translateY(-20px); }
    }

    @keyframes rotate {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }

    .timeline-steps {
        list-style: none;
        padding: 0;
        
        .timeline-step {
            position: relative;
            padding-left: 30px;
            margin-bottom: 30px;
            
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                width: 12px;
                height: 12px;
                background: var(--theme-color);
                border-radius: 50%;
                transform: translateY(-50%);
            }
            
            &.active::before {
                background: var(--theme-color);
                box-shadow: 0 0 0 6px rgba(var(--theme-color-rgb), 0.2);
            }
            
            &::after {
                content: '';
                position: absolute;
                left: 5px;
                top: 50%;
                width: 2px;
                height: calc(100% + 30px);
                background: rgba(var(--theme-color-rgb), 0.2);
                transform: translateY(0);
            }
            
            &:last-child::after {
                display: none;
            }
        }
    }

    .custom-accordion {
        .accordion-content {
            padding: 20px;
            background: rgba(255, 255, 255, 0.05);
            border-radius: 10px;
            margin-top: 10px;
            
            h4 {
                color: var(--theme-color);
                margin-bottom: 10px;
                font-size: 1.2rem;
            }
            
            p {
                margin: 0;
                line-height: 1.6;
            }
        }
    }

    .features-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        
        .feature-item {
            background: rgba(255, 255, 255, 0.05);
            border-radius: 15px;
            padding: 25px;
            transition: all 0.3s ease;
            
            &:hover {
                transform: translateY(-5px);
                background: rgba(255, 255, 255, 0.1);
            }
            
            .feature-icon {
                font-size: 2rem;
                color: var(--theme-color);
                margin-bottom: 15px;
            }
            
            .feature-content {
                h4 {
                    font-size: 1.1rem;
                    margin-bottom: 10px;
                    color: var(--theme-color);
                }
                
                p {
                    margin: 0;
                    font-size: 0.9rem;
                    line-height: 1.6;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .features-grid {
            grid-template-columns: 1fr;
        }
    }
}

@media (max-width: 768px) {
    .it-form-section {
        padding: 60px 0;

        .section_heading {
            .heading_text {
                font-size: 2rem;
            }
        }

        .position-card {
            margin-bottom: 20px;
        }

        .it-form-wrapper {
            padding: 30px;
        }
    }
}
