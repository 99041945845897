.general-form-section {
    padding: 100px 0;
    background-color: #f8f9fa;

    .section_heading {
        margin-bottom: 50px;

        .heading_text {
            font-size: 36px;
            font-weight: 700;
            color: #1a1a1a;
            margin-bottom: 15px;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
                width: 50px;
                height: 3px;
                background: linear-gradient(to right, #007bff, #00bcd4);
            }
        }

        .heading_description {
            font-size: 18px;
            color: #666;
            max-width: 600px;
            margin: 20px auto 0;
        }
    }

    .general-form-wrapper {
        background: #fff;
        padding: 40px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

        .form-group {
            margin-bottom: 25px;
            position: relative;

            .form-control {
                height: 50px;
                border: 2px solid #e1e1e1;
                border-radius: 8px;
                padding: 10px 15px;
                font-size: 16px;
                transition: all 0.3s ease;
                background-color: #f8f9fa;

                &:focus {
                    border-color: #007bff;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.1);
                    background-color: #fff;
                }

                &.is-invalid {
                    border-color: #dc3545;
                    background-color: #fff;
                }

                &::placeholder {
                    color: #999;
                    font-size: 14px;
                }
            }

            select.form-control {
                cursor: pointer;
                
                option {
                    padding: 10px;
                }
            }

            textarea.form-control {
                height: auto;
                resize: vertical;
                min-height: 100px;
            }
        }

        .invalid-feedback {
            font-size: 13px;
            color: #dc3545;
            margin-top: 5px;
            font-weight: 500;
        }

        .btn-primary {
            padding: 12px 40px;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 30px;
            background: linear-gradient(45deg, #007bff, #00bcd4);
            border: none;
            transition: all 0.3s ease;
            box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);

            &:disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }

            &:hover:not(:disabled) {
                transform: translateY(-2px);
                box-shadow: 0 8px 20px rgba(0, 123, 255, 0.4);
            }

            &:active:not(:disabled) {
                transform: translateY(1px);
            }
        }

        .alert {
            margin-top: 25px;
            border-radius: 8px;
            font-size: 15px;
            font-weight: 500;
            
            &.alert-success {
                background-color: #d4edda;
                border-color: #c3e6cb;
                color: #155724;
            }
            
            &.alert-danger {
                background-color: #f8d7da;
                border-color: #f5c6cb;
                color: #721c24;
            }
        }
    }
}

.non-it-jobs-section {
    padding: 80px 0;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);

    .section_heading {
        margin-bottom: 50px;

        .heading_text {
            font-size: 2.5rem;
            font-weight: 700;
            color: #2c3e50;
            margin-bottom: 1rem;
        }

        .heading_description {
            font-size: 1.1rem;
            color: #6c757d;
        }
    }

    .position-card {
        background: #ffffff;
        border-radius: 15px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
        transition: transform 0.3s ease;
        height: 100%;
        
        &:hover {
            transform: translateY(-5px);
        }

        .card-body {
            padding: 25px;
        }

        .position-title {
            font-size: 1.25rem;
            font-weight: 600;
            color: #2c3e50;
            margin-bottom: 1rem;
        }

        .position-details {
            margin-bottom: 1rem;

            p {
                margin-bottom: 0.5rem;
                color: #6c757d;
                font-size: 0.9rem;

                i {
                    margin-right: 8px;
                    color: #3498db;
                }
            }
        }

        .skills-section {
            h4 {
                font-size: 1rem;
                color: #2c3e50;
                margin-bottom: 0.5rem;
            }

            .skills-list {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                .skill-tag {
                    background: #e1f0ff;
                    color: #3498db;
                    padding: 4px 12px;
                    border-radius: 20px;
                    font-size: 0.8rem;
                }
            }
        }
    }

    .benefits-section {
        background: #ffffff;
        padding: 50px;
        border-radius: 20px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
        margin: 50px 0;

        h2 {
            font-size: 2rem;
            color: #2c3e50;
            margin-bottom: 2rem;
        }

        .benefit-item {
            text-align: center;
            padding: 20px;

            i {
                font-size: 2.5rem;
                color: #3498db;
                margin-bottom: 1rem;
            }

            h3 {
                font-size: 1.25rem;
                color: #2c3e50;
                margin-bottom: 0.5rem;
            }

            p {
                color: #6c757d;
                font-size: 0.9rem;
            }
        }
    }

    .application-section {
        background: #ffffff;
        padding: 50px;
        border-radius: 20px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);

        h2 {
            font-size: 2rem;
            color: #2c3e50;
            margin-bottom: 2rem;
        }

        .non-it-form {
            .form-group {
                margin-bottom: 1.5rem;

                .form-control {
                    padding: 12px 15px;
                    border: 1px solid #e1e8ef;
                    border-radius: 10px;
                    font-size: 0.95rem;
                    transition: border-color 0.3s ease;

                    &:focus {
                        border-color: #3498db;
                        box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.25);
                    }

                    &::placeholder {
                        color: #95a5a6;
                    }

                    &.is-invalid {
                        border-color: #e74c3c;
                    }
                }

                textarea.form-control {
                    resize: vertical;
                }

                .invalid-feedback {
                    font-size: 0.85rem;
                    color: #e74c3c;
                }
            }

            .submit-btn {
                background: linear-gradient(135deg, #3498db 0%, #2980b9 100%);
                border: none;
                padding: 12px 30px;
                font-size: 1rem;
                font-weight: 600;
                border-radius: 25px;
                transition: transform 0.3s ease;

                &:hover {
                    transform: translateY(-2px);
                }

                &:disabled {
                    background: #95a5a6;
                    transform: none;
                }
            }
        }

        .alert {
            border-radius: 10px;
            padding: 15px 20px;
            margin-top: 2rem;

            &.alert-success {
                background-color: #d4edda;
                border-color: #c3e6cb;
                color: #155724;
            }

            &.alert-danger {
                background-color: #f8d7da;
                border-color: #f5c6cb;
                color: #721c24;
            }
        }
    }
}

@media (max-width: 768px) {
    .general-form-section {
        padding: 60px 0;

        .section_heading {
            .heading_text {
                font-size: 28px;
            }

            .heading_description {
                font-size: 16px;
            }
        }

        .general-form-wrapper {
            padding: 25px;

            .form-group {
                margin-bottom: 20px;

                .form-control {
                    height: 45px;
                    font-size: 14px;
                }
            }

            .btn-primary {
                padding: 10px 30px;
                font-size: 14px;
            }
        }
    }
}
