/* 5.02 - Contact Page - Start
================================================== */
.contact_section {
	&:has(.decoration_item) {
		padding-top: 180px;
	}
	.shape_image_1 {
		left: 0;
		top: 50%;
		max-width: 192px;
		transform: translateY(-50%);
	}
	.shape_image_2 {
		right: 0;
		top: 50%;
		max-width: 298px;
		transform: translateY(-50%);
	}
	.shape_image_3 {
		top: 0;
		left: 0;
		right: 0;
		text-align: center;
		img {
			width: calc(100% - 530px);
			transform: translateX(-5px);
		}
	}
}

.contact_info_box {
	.iconbox_block {
		padding: 60px 30px 52px;
	}
	.iconbox_block .iconbox_icon img {
		max-width: 24px;
	}
	.iconbox_block .iconbox_icon {
		width: 60px;
		height: 60px;
		margin: 0 0 32px;
	}
	.iconbox_block .iconbox_title {
		line-height: 1;
		font-size: 18px;
	}
}

.contact_method_box {
	padding: 46px 50px 50px;
	background-color: var(--bs-white);
	border-radius: var(--bs-border-radius);
	box-shadow: 0 4px 23px 0 rgba(174, 191, 210, 0.3);

	.contact_method_list {
		margin-bottom: 50px;
	}
}

.contact_method_list {
	gap: 10px;
	a {
		gap: 20px;
		align-items: center;
		display: inline-flex;
		color: var(--bs-dark);
		&:hover {
			color: var(--bs-primary);
		}
	}
	.icon {
		width: 40px;
		height: 40px;
		flex: 0 0 auto;
		align-items: center;
		border-radius: 100%;
		display: inline-flex;
		color: var(--bs-white);
		justify-content: center;
		background-color: var(--bs-primary);
		i {
			animation: none;
		}
	}
	.text {
		font-size: 17px;
		font-weight: 500;
		font-family: 'Axiforma Medium';
	}
}

.support_step {
	gap: 10px;
	z-index: 1;
	position: relative;
	&:before {
		top: 0;
		bottom: 0;
		left: 20px;
		width: 1px;
		content: '';
		z-index: -1;
		position: absolute;
		background-color: var(--bs-primary);
	}
	>li {
		gap: 10px;
		display: flex;
		align-items: center;
	}
	.serial_number {
		width: 40px;
		height: 40px;
		display: flex;
		flex: 0 0 auto;
		padding-top: 4px;
		font-weight: 500;
		border-radius: 100%;
		align-items: center;
		justify-content: center;
		color: var(--bs-primary);
		font-family: 'Axiforma Medium';
		background-color: var(--bs-white);
		border: 1px solid var(--bs-primary);
	}
	.text {
		font-size: 17px;
		padding-top: 4px;
		font-weight: 500;
		font-family: 'Axiforma Medium';
	}
}

.instant_contact_form {
	padding: 50px;
	background-color: #007bb5;
	border-radius: var(--bs-border-radius);
	box-shadow: 0 4px 23px 0 rgba(174, 191, 210, 0.3);
	.small_title {
		line-height: 1;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 26px;
		color: var(--bs-dark);
		text-transform: uppercase;
		font-family: 'Axiforma Medium';
		i {
			color: var(--bs-primary);
		}
	}

	.form_title {
		font-size: 26px;
		font-weight: 600;
		line-height: 36px;
		margin-bottom: 19px;
		font-family: 'Axiforma SemiBold';
	}

	.form-group {
		.form-control,
		.form-select {
			box-shadow: none;
			padding-left: 50px;
			caret-color: var(--bs-dark);
			border: 1px solid;
			&:focus {
				background-color: var(--bs-light);
				border-color: rgba(0, 68, 235, 0.1);
			}
		}
		.input_title {
			top: 22px;
			left: 22px;
			position: absolute;
			i {
				color: #676767;
				animation: none;
			}
		}
	}
}

.gmap_canvas {
	iframe {
		width: 100%;
		height: 630px;
		border-radius: var(--bs-border-radius-sm);
	}
}
/* 5.02 - Contact Page - End
================================================== */