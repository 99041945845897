/* General Styling */
.career-page {
    font-family: Arial, sans-serif;
  
  
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .section-description {
    font-size: 1.1rem;
    margin-bottom: 30px;
  }
  
  /* Career Intro Section */
  .career-intro {
    padding: 60px 0;
    background-color: #f4f6f9;
  }
  
  .why-join-us {
    padding: 60px 0;
    background-color: #ffffff;
  }
  
  .why-card {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .why-card h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  
  .job-categories {
    padding: 60px 0;
    background-color: #f4f6f9;
  }
  
  .job-card {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .job-card h3 {
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  /* Hiring Process Section */
  .hiring-process {
    padding: 60px 0;
    background-color: #ffffff;
  }
  
  .process-step {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    text-align: center;
  }
  
  .process-step h4 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}

.Staffing_H1{
  background: #E20000;
  background: linear-gradient(to right, #E20000 0%, #1623B1 50%, #124EAF 50%, #36B5E7 100%);  
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}



.card {
  background: white;
  width: 80%;
  height: 100%;
  padding: 3px;
  position: relative;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 1.5em;
  color: rgb(88 199 250 / 0%);
  cursor: pointer;
  font-family: cursive;
}

.card:hover {
  color: rgb(88 199 250 / 100%);
  transition: color 1s;
}
// .card:hover:before,
// .card:hover:after {
//   animation: none;
//   opacity: 0;
// }

// .card::before {
//   content: "";
//   width: 104%;
//   height: 102%;
//   border-radius: 8px;
//   background-image: linear-gradient(
//     var(--rotate),
//     #5ddcff,
//     #3c67e3 43%,
//     #4e00c2
//   );
//   position: absolute;
//   z-index: -1;
//   top: -1%;
//   left: -2%;
//   animation: spin 2.5s linear infinite;
// }

// .card::after {
//   position: absolute;
//   content: "";
//   top: calc(var(--card-height) / 6);
//   left: 0;
//   right: 0;
//   z-index: -1;
//   height: 100%;
//   width: 100%;
//   margin: 0 auto;
//   transform: scale(0.8);
//   filter: blur(calc(var(--card-height) / 6));
//   background-image: linear-gradient(
//     var(--rotate),
//     #5ddcff,
//     #3c67e3 43%,
//     #4e00c2
//   );
//   opacity: 1;
//   transition: opacity 0.5s;
//   animation: spin 2.5s linear infinite;
// }

// @keyframes spin {
//   0% {
//     --rotate: 0deg;
//   }
//   100% {
//     --rotate: 360deg;
//   }
// }
