
ul.smothscroll {
    position: fixed;
    bottom: 10%;
    right: 30px;
    list-style: none;
    z-index: 99;
}

ul.smothscroll a {
    z-index: 1;
    width: 50px;
    height: 50px;
    display: block;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 4px 7px rgba(178, 190, 205, 0.29);
    color: var(--color-white);
    border-radius: 50%;
    font-size: 14px;
}
.icon-bar {
	position: fixed;
	top: 30%;
	right: 100%;
	transform: translateX(104%);
  }
  .icon-bar:hover {
	position: fixed;
	right: 100%;
	transform: translateX(120%);
  }
  
  .icon-bar a {
	display: block;
	text-align: center;
	padding: 16px;
  margin: 4px;
	transition: all 0.3s ease;
	color: white;
	font-size: 24px;
  }
  
  .icon-bar a:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.36);
    transform: rotate(360deg);
    transition: all 1s;
  }


ul.smothscroll a:hover {
    margin-bottom: 4px;
}

@media(max-width:767px) {
    ul.smothscroll a {
        width: 30px;
        height: 30px;
        line-height: 25px;
    }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-in-out forwards; /* "forwards" keeps the final state of the animation */
}
